@import "./constants";
@font-face {
    font-family: "Myriad Pro";
    font-style: normal;
    font-weight: normal;
    src: local("Myriad Pro Condensed"),
        url("../assets/fonts/MYRIADPRO-COND.woff") format("woff");
}

@font-face {
    font-family: "Myriad Pro Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Myriad Pro Condensed Italic"),
        url("../assets/fonts/MYRIADPRO-CONDIT.woff") format("woff");
}

html {
    margin: 0 auto;
    max-width: 1500px;
}

body {
    background-color: $dark-blue;
    margin: 0;
}

html,
body {
    font-family: $acumin-pro;
}

span {
    &.italics {
        font-style: italic;
    }
    &.underline {
        text-decoration: underline;
    }
}

.mobile-only {
    display: none !important;
}

h1 {
    font-size: 40px;
}

.h2,
h2 {
    font-size: 40px;
    font-weight: 800;
}

h3 {
    font-size: 30px;
    font-weight: 800;
    margin: 0;
}

h5 {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
}

h6 {
    font-size: 20px;
    margin: 0;
}

.relative {
    position: relative;
    border-left: 2px solid $dark-blue;
}

.layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.clickable {
    cursor: pointer;
}

.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: 100%;
    margin: 110px auto 0 auto;
    .MuiGrid-spacing-xs-16-117 {
        width: 100%;
        margin: unset;
    }
    .home-page {
        .quotation {
            display: flex;
            color: $white;
            font-family: $acumin-pro;
            font-size: 30px;
            margin: 20px 0;
        }
        .grid {
            background-color: $white;
            color: $dark-blue;
            text-align: center;

            .padded-column {
                padding: 6px 10px;

                @media screen and (max-width: 740px) {
                    padding: 0;
                }
            }

            .blue-bkg {
                background-color: #00aef3;

                &.first-item {
                    padding: 80px 30px 20px 30px;
                }
                &.second-item {
                    padding: 30px 30px 70px 30px;
                }
            }
            h2 {
                margin-top: 25px;
                margin-bottom: 10px;
                line-height: 42px;
            }
            h5 {
                font-size: 28px;
                line-height: 28px;
            }
            .message {
                max-width: 454px;
                margin: auto;
                margin-top: 20px;
                margin-bottom: 45px;
                text-align: center;
                letter-spacing: 1px;
                &.big {
                    max-width: 550px;
                }
            }
            &-column {
                border-right: 2px solid $dark-blue;
            }
            &-row {
                // border-bottom: 2px solid $dark-blue;
            }
            &-last {
                border-bottom: 2px solid $dark-blue;
            }
            &-item {
                padding: 50px 30px;
                width: 100%;
                max-width: unset;
                min-height: 200px;
            }
            .small-column {
                max-width: 45%;
                flex-basis: 45%;
            }
            .big-column {
                max-width: 55%;
                flex-basis: 55%;
            }
            &-overlay {
                position: absolute;
                padding: 74px 50px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                .reason-expanded {
                    color: $white;
                    border-bottom: 2px solid $white;
                    padding-bottom: 10px;
                    margin-bottom: 50px;
                    text-align: left;
                    h5 {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        line-height: 35px;
                    }
                }
                .action-button {
                    margin: auto;
                    width: fit-content;
                }
            }
            &-with-bkg {
                padding: unset;
            }
        }
    }
}

.popup-overlay {
    z-index: 9999 !important;
    .popup-content {
        padding: 0;
        border: 0;
        max-width: 550px;
        z-index: 9999 !important;
        overflow-y: auto;
        max-height: 100vh;
        .modal {
            &.red {
                border: 20px solid $red;
            }
            &.blue {
                border: 20px solid $dark-blue;
            }
            .close-container {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                a {
                    font-size: 46px;
                    margin-right: 16px;
                    font-weight: 200;
                }
            }
        }
    }
}

.MuiMenu-paper-345,
.MuiModal-root-470,
.MuiModal-root-297 {
    z-index: 19999 !important;
}

.MuiInput-root-249 {
    width: 100%;
}

.MuiGrid-justify-xs-space-between-89,
.MuiGrid-direction-xs-column-73 {
    display: flex;
}

.MuiSelect-selectMenu-283 {
    text-align: left;
}

.form-control {
    width: 100%;
}

.button {
    border-radius: 0 !important;
    text-transform: capitalize !important;
    &.MuiButton-outlinedPrimary-50 {
        border: 1px solid $dark-blue !important;
    }
    &.MuiButton-root-41 {
        border: 1px solid rgba(0, 0, 0, 0.23);
        padding: 10px 24px;
    }
}

.action-buttons {
    font-size: 40px;
    font-weight: 800;
    // width: 100%;
    display: flex;
    // flex-direction: row;
    text-align: center !important;
    justify-content: space-between;
    background-color: $white;
    // padding: 40px 130px 40px 164px;
    padding: 40px 100px 40px 100px;
}

.action-button {
    font-size: 40px;
    font-weight: 800;
    // display: inline-block;
    width: 400px; //or as wide as you want them to be
    // width: 8em;
    // box-sizing: border-box;
    color: $white;
    background-color: $red;
    padding: 15px 30px;
    font-weight: bold;
    &.blue {
        background-color: $dark-blue;
    }
    &.h2 {
        width: unset;
    }
}

span {
    &.yellow {
        color: $yellow;
        &.padded {
            margin: 0 8px;
        }
    }
}

.reasons-grid {
    padding: 0 50px !important;
    color: $white;
    .filters {
        display: flex;
        margin-bottom: 70px;
        label {
            color: $white;
            font-size: 17px;
            &.MuiFormLabel-focused-380 {
                color: $white;
            }
        }
        .select {
            border-bottom: 1px solid $white !important;
            &.big {
                min-width: 96px;
            }
        }
        .text-field {
            margin: 0 50px;
            .MuiInput-underline-200:before {
                border-bottom: 1px solid $white;
            }
            .MuiInput-underline-200:after {
                border-bottom: 1px solid $white;
            }
        }
        #select-region,
        #select-views,
        #select-vote,
        #select-gender,
        #select-age {
            min-width: 100px;
        }
        #select-region,
        #select-views,
        #select-vote,
        #select-gender,
        #select-age,
        #age {
            color: $white;
        }
        .MuiInput-underline-200:hover:not(.MuiInput-disabled-199):not(.MuiInput-focused-198):not(.MuiInput-error-201):before {
            border-color: $white;
        }
        svg {
            color: $white;
        }
        .buttons {
            margin-top: 20px;
            display: flex;
        }
        .MuiSelect-select-336 {
            text-align: left;
        }
    }
    .MuiGrid-grid-sm-12-124 {
        flex-basis: unset;
    }
    .progress-container {
        width: 100%;
        justify-content: center;
        display: flex;
        height: 50px;
        margin-bottom: 150px;
        align-items: center;
        .progress-bar {
            color: $white;
        }
    }
    .content {
        min-height: 200px;
    }
    .post-card {
        border: 2px solid $white;
        border-radius: 0;
        width: calc(100% - 16px);
        margin: auto;
        margin-bottom: 40px;
        background-color: $dark-blue;
        position: relative;
        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px 38px;
        }
        .info {
            text-align: center;
            margin-bottom: 25px;
        }
        .state {
            font-size: 17px;
        }
        .secondary {
            font-size: 16px;
            font-weight: 400;
        }
        .user {
            color: $grey;
            margin-top: 10px;
            margin-bottom: 30px;
        }
        .reason {
            width: 100%;
            border-bottom: 2px solid $white;
            padding-bottom: 4px;
            margin-bottom: 30px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .reason-expanded {
            width: 100%;
            border-bottom: 2px solid $white;
            padding-bottom: 4px;
            margin-bottom: 30px;
        }
    }
}

.home-message {
    display: flex;
    flex-direction: column;
    height: 200px;
    color: $white;
    background-color: $dark-blue;
    text-align: center;
    &.red {
        background-color: $red;
    }
}

.footer {
    padding: 60px;
    height: fit-content !important;
    justify-content: space-between !important;
    .first-column {
        align-items: flex-start !important;
    }
    .copyright {
        color: $dark-blue;
        margin-top: 10px;
    }
    .social-media-icon {
        min-width: unset;
        margin-top: 20px;
    }
    .link {
        display: block;
        font-family: $acumin-pro;
        font-size: 20px;
        color: $dark-blue;
        margin-bottom: 2px;
    }
    .email-icon {
        margin-top: 10px;
    }
    .MuiGrid-justify-xs-center-87 {
        justify-content: center;
        align-items: center;
    }
}

.about-page {
    &-banner {
        width: 100%;
        height: 220px;
        object-fit: cover;
    }
    .page-title {
        color: $white;
        text-align: center;
        margin-bottom: 38px;
        h1 {
            font-size: 36px;
        }
        &.contact {
            margin-bottom: 25px;
            margin-top: 50px;
        }
    }
    &-content {
        display: flex;
        max-width: 805px;
        margin: auto;
        width: 70%;
        color: $white;
        h5 {
            flex: 1;
            padding: 0 40px;
            font-size: 22px;
        }
    }
    form {
        width: 300px;
        margin: auto;
        margin-bottom: 200px;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .MuiFormLabel-root-85 {
        color: $white;
    }
    .text-field {
        .MuiInput-underline-96:before {
            border-bottom: 1px solid $white;
        }
        .MuiInput-underline-96:after {
            border-bottom: 1px solid $white;
        }
    }
    .MuiOutlinedInput-multiline-128 {
        padding: 0;
    }
    .MuiInputBase-inputType-118 {
        font-size: 18px;
    }
    .MuiOutlinedInput-root-122 .MuiOutlinedInput-notchedOutline-129 {
        border: none;
    }
    #email,
    #message {
        color: $white;
    }
    #message {
        border: 1px solid $white;
        margin-top: 24px;
        height: auto;
        padding: 10px 14px;
    }
    .MuiInput-underline-96:hover:not(.MuiInput-disabled-95):not(.MuiInput-focused-94):not(.MuiInput-error-97):before {
        border-color: $white;
    }
    .button {
        color: $white;
        border-color: $white !important;
        font-size: 18px;
        margin-top: 20px;
    }
}

.contribute-page {
    &-banner {
        width: 100%;
        height: 220px;
        object-fit: cover;
    }
    .page-title {
        color: $white;
        text-align: center;
        margin-bottom: 38px;
        h1 {
            font-size: 36px;
        }
        &.contact {
            margin-bottom: 25px;
            margin-top: 50px;
        }
    }
    &-content {
        display: flex;
        max-width: 805px;
        margin: auto;
        width: 70%;
        color: $white;
        h5 {
            flex: 1;
            padding: 0 40px;
            font-size: 22px;
        }
    }
    form {
        width: 300px;
        margin: auto;
        margin-bottom: 200px;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .MuiFormLabel-root-85 {
        color: $white;
    }
    .text-field {
        .MuiInput-underline-96:before {
            border-bottom: 1px solid $white;
        }
        .MuiInput-underline-96:after {
            border-bottom: 1px solid $white;
        }
    }
    .MuiOutlinedInput-multiline-128 {
        padding: 0;
    }
    .MuiInputBase-inputType-118 {
        font-size: 18px;
    }
    .MuiOutlinedInput-root-122 .MuiOutlinedInput-notchedOutline-129 {
        border: none;
    }
    #email,
    #message {
        color: $white;
    }
    #message {
        border: 1px solid $white;
        margin-top: 24px;
        height: auto;
        padding: 10px 14px;
    }
    .MuiInput-underline-96:hover:not(.MuiInput-disabled-95):not(.MuiInput-focused-94):not(.MuiInput-error-97):before {
        border-color: $white;
    }
    .button {
        color: $white;
        background-color: #2650d4;
        border-color: #2650d4 !important;
        font-size: 18px;
        margin-top: 20px;
        width: 100px;
        &.left {
            margin-right: 10px;
            margin-left: 10px;
        }
    }
    .button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        .text-field {
            margin: 0 auto;
        }
    }
}

@media screen and (min-width: 900px) and (max-width: 980px) {
    .large-desktop-only {
        display: none !important;
    }
    .action-buttons {
        font-size: 20px;
        font-weight: 400;
        padding: 0;
        padding: 40px 40px;
        // overflow: hidden;
        // width: auto;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
    }
    .action-button {
        font-size: 40px;
        font-weight: 800;
        overflow: hidden;
        //     &.h2 {
        //         margin: 0 auto !important;
        //         width: 200px !important;
        //     }
        // }
        // .grid-column {
        //     align-items: center !important;
        //     justify-content: center !important;
    }
}

@media screen and (min-width: 825px) and (max-width: 900px) {
    .large-desktop-only {
        display: none !important;
    }
    .medium-desktop-only {
        display: none !important;
    }
    .action-buttons {
        // overflow: hidden;
        // width: auto;
        padding: 0;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
    }
    .action-button {
        font-size: 25px !important;
        font-weight: 800 !important;
        // padding: 40px 40px !important;
        min-width: 300px !important;
        height: auto !important;
    }
}

@media screen and (min-width: 740px) and (max-width: 825px) {
    html {
        align-items: center;
        justify-content: center;
        overflow-x: hidden !important;
        // position: fixed;
        // bottom: 1000px;
        // right: 1000px;
        // top: 1000px;
        // left: 1000px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
    }
    .large-desktop-only {
        display: none !important;
    }
    .medium-desktop-only {
        display: none !important;
    }
    .action-buttons {
        // overflow: hidden;
        // width: auto;
        padding: 0;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        flex-direction: column;
        padding: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .action-button {
        font-size: 25px !important;
        font-weight: 800 !important;
        // padding: 40px 40px !important;
        width: 90%;
        min-width: 300px !important;
        height: auto !important;
        padding: 10px 10px !important;
        margin-bottom: 20px !important;
        &.blue {
            margin-bottom: 0px !important;
        }
    }
}

@media screen and (min-width: 420px) and (max-width: 740px) {
    html {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // overflow-x: hidden !important;
        // position: fixed;
        // bottom: 1000px;
        // right: 1000px;
        // top: 1000px;
        // left: 1000px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
    }
    .large-desktop-only {
        display: none !important;
    }
    .medium-desktop-only {
        display: none !important;
    }
    .action-buttons {
        // overflow: hidden;
        // width: auto;
        padding: 0;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        flex-direction: column;
        padding: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .action-button {
        font-size: 25px !important;
        font-weight: 800 !important;
        // padding: 40px 40px !important;
        width: 90%;
        min-width: 300px !important;
        height: auto !important;
        padding: 10px 10px !important;
        margin-bottom: 20px !important;
        &.blue {
            margin-bottom: 0px !important;
        }
    }
    h2,
    .h2 {
        font-size: 32px;
    }
    .grid {
        &[class*="MuiGrid-container"] {
            width: unset;
            flex-direction: column;
            align-items: center !important;
            justify-content: center !important;
        }
        &-with-bkg {
            min-height: 500px !important;
            padding: 0 !important;
        }
        &-overlay {
            padding: 42px 25px !important;
        }
        .reason-expanded {
            margin-bottom: 40px !important;
            h5 {
                font-size: 24px !important;
                line-height: 25px !important;
            }
        }
    }
    &[class*="MuiGrid-spacing-xs"] {
        width: 100%;
        margin: 0;
    }
    .popup-overlay .popup-content {
        width: calc(100% - 30px);
    }
    .container .home-page {
        overflow-wrap: normal;
        .grid-item {
            width: 100%;
            text-align: center !important;
            justify-content: center !important;
            padding: 20px 20px;
            *.action-button {
                margin-top: 30px;
                margin-bottom: 30px;
            }
            .h5,
            .message {
                margin: 0 auto;
                text-align: center;
                overflow: wrap;
                font-size: 30px;
                letter-spacing: 0.7px;
                margin-bottom: 15px;
            }
            // h2,
            // .h2 {
            //     text-align: center !important;
            //     justify-content: center !important;
            //     max-width: 400px;
            //     font-size: 32px;
            // }
        }
        .grid-column {
            // border-right: none;
            border-bottom: 2px solid $dark-blue;
            border-right: unset;
            text-align: center !important;
            justify-content: center !important;
            align-items: center !important;
            .h5,
            .message {
                margin: 0 auto;
                text-align: center;
                font-size: 30px;
                overflow: wrap;
                width: calc(100% - 200px);
                &.big {
                    max-width: calc(100% - 200px);
                }
            }
        }
        *[class*="MuiGrid-grid-xs"] {
            &[class*="MuiGrid-grid-sm"] {
                max-width: 100%;
                width: 100vw !important;
                &.column {
                    // margin: 0 !important;
                    // padding: 0 !important;
                    // margin-left: calc(-50vw);
                }
            }
        }
    }
    .reasons-grid {
        padding: 0 30px !important;
        margin: 0;
        .MuiGrid-item-71 {
            margin: 0;
            box-sizing: border-box;
            width: 100%;
        }
        .post-card {
            width: 100%;
        }
        .post-card-content {
            padding: 30px 18px;
        }
        .filters {
            flex-direction: column;
            width: 100%;
            .text-field {
                margin: 0;
            }
            .form-control {
                margin: 0 !important;
            }
        }
    }
    .MuiGrid-spacing-xs-16-93 {
        width: 100% !important;
        margin: 0 !important;
    }
    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        .text-field {
            margin: 0 auto;
        }
    }
    .help {
        flex-direction: column;
    }
    .lol {
        max-width: 100vw !important;
    }
}

@media (max-width: 420px) {
    html {
        align-items: center;
        justify-content: center;
        overflow-x: hidden !important;
        // position: fixed;
        // bottom: 1000px;
        // right: 1000px;
        // top: 1000px;
        // left: 1000px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
    }
    .large-desktop-only {
        display: none !important;
    }
    .desktop-only {
        display: none !important;
    }
    .medium-desktop-only {
        display: none !important;
    }
    .mobile-only {
        display: flex !important;
    }
    .header .nav-container {
        width: 100% !important;
    }
    .help {
        flex-direction: unset;
    }
    .lol {
        max-width: unset;
    }
    h2,
    .h2 {
        font-size: 32px;
    }
    .footer {
        display: flex;
        position: relative;
        // all: unset;
        // width: 100vw !important;
        // padding-top: 20px;
        // flex-direction: column;
        // width: -webkit-fit-content !important;
        // width: 100vw;
        align-items: center;
        text-align: center;
        justify-content: center;
        *[class*="MuiGrid-justify-xs-center"] {
            text-align: center;
        }
        // .MuiGrid-justify-xs-center-155 {
        //     text-align: center;
        // }
        *[class*="MuiGrid-align-items-xs-flex-start"] {
            align-items: center;
            margin-bottom: 40px;
            margin-top: 35px;
        }
        // .MuiGrid-align-items-xs-flex-start-79 {
        //     align-items: center;
        //     margin-bottom: 40px;
        //     margin-top: 35px;
        // }
        .quotation {
            margin-top: 35px;
        }
    }
    .about-page {
        &-banner {
            height: 100px;
        }
        &-content {
            width: 100%;
            color: #fff;
            flex-direction: column;
            margin-bottom: 50px;
            h5 {
                margin-bottom: 28px;
            }
        }
    }
    .container .home-page .quotation {
        width: 100vw !important;
        font-size: 2vh;
        text-align: center;
        align-items: center !important;
        justify-content: center !important;
        padding: 20px 20px;
        margin: 0 auto;
    }
    .grid {
        *.footer {
            margin: "none";
        }
    }
    .image-banner .image-overlay .main-quote {
        width: 100vw !important;
        font-size: 5vh !important;
    }
    .action-buttons {
        flex-direction: column;
        padding: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .action-button {
        padding: 10px 10px !important;
        margin-bottom: 20px !important;
        &.blue {
            margin-bottom: 0px !important;
        }
    }
    .image-banner .image-overlay .secondary-labels {
        padding: 100px 70px;
        text-align: center;
        font-size: 22px;
        justify-content: center;

        .large-desktop-only {
            font-weight: 600;
        }
    }
    .home-message {
        flex-direction: column;
        height: auto;
        padding: 25px;
        &.h2 {
            font-size: 40px;
            &.mobile-only {
                font-size: 25px;
            }
        }
    }
    .grid {
        width: unset;
        &[class*="MuiGrid-container"] {
            width: unset;
            flex-direction: column;
            align-items: center !important;
            justify-content: center !important;
        }
        &-with-bkg {
            min-height: 500px !important;
            padding: 0 !important;
        }
        &-overlay {
            padding: 42px 25px !important;
        }
        .reason-expanded {
            margin-bottom: 40px !important;
            h5 {
                font-size: 24px !important;
                line-height: 25px !important;
            }
        }
    }
    &[class*="MuiGrid-spacing-xs"] {
        width: 100%;
        margin: 0;
    }
    .popup-overlay .popup-content {
        width: calc(100% - 30px);
    }
    .container .home-page {
        overflow-wrap: normal;
        .grid-item {
            text-align: center !important;
            justify-content: center !important;
            padding: 20px 20px;
            *.action-button {
                margin-top: 20px;
            }
            .h5,
            .message {
                margin: 0 auto;
                text-align: center;
                overflow: wrap;
                font-size: 20px;
                letter-spacing: 0.7px;
                margin-bottom: 15px;
            }
        }
        .grid-column {
            // border-right: none;
            border-bottom: 2px solid $dark-blue;
            border-right: unset;
            text-align: center !important;
            justify-content: center !important;
            align-items: center !important;
            .h5,
            .message {
                margin: 0 auto;
                text-align: center;
                font-size: 20px;
                overflow: wrap;
                // width: calc(100% - 200px);
                &.big {
                    //        max-width: calc(100% - 200px);
                }
            }
        }
    }
    .reasons-grid {
        padding: 0 30px !important;
        margin: 0;
        .MuiGrid-item-71 {
            margin: 0;
            box-sizing: border-box;
            width: 100%;
        }
        .post-card {
            width: 100%;
        }
        .post-card-content {
            padding: 30px 18px;
        }
        .filters {
            flex-direction: column;
            width: 100%;
            .text-field {
                margin: 0;
            }
            .form-control {
                margin: 0 !important;
            }
        }
    }
    .MuiGrid-spacing-xs-16-93 {
        width: 100% !important;
        margin: 0 !important;
    }
    .container .home-page .grid {
        display: unset;
    }
    .container .home-page .grid-overlay {
        width: unset;
    }
    .footer {
        all: unset;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .help {
        width: unset;
    }
    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        .text-field {
            margin: 0 auto;
        }
    }
    // .lastone {
    //     width: calc(100% + 15vw) !important;
    // }
}
