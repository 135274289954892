.background-image {
    object-fit: cover;
}

.bkg-opacity {
    top: 0;
    left: 0;
}

.layer {
    position: absolute;
    width: 100%;
    height: 100%;
}