@import "../../styles/constants";
.header {
    background-color: $dark-blue;
    border-bottom: 2px solid white;
    min-height: 110px;
    .nav-container {
        max-width: 1500px;
        justify-content: space-between;
        width: calc(100% - 48px);
        .social-media-icon {
            min-width: 48px;
        }
        .right-content {
            display: flex;
            align-items: center;
        }
    }

    .logo-icon {
        transform: scale(0.7);
        margin-left: -14px;
    }
    .contribute-button {
        // background-color: $red;
        color: $white;
        font-weight: bold;
        font-size: 23px;
        padding: 0 14px 5px;
        margin-left: 22px;
        margin-right: 10px;
    }
    .sign-in-button {
        color: $white;
        text-transform: capitalize;
        font-size: 16px;
        letter-spacing: 0.4px;
    }

    .dropdown-container {
        .dropdown-menu {
            position: absolute;
            background-color: white;
            padding: 12px;
            right: 0px;
            border-radius: 0px;
            display: flex;
            flex-direction: column;

            &-item {
                padding: 5px 0;
                cursor: pointer;
                color: $dark-blue;
            }
        }
    }
}

@media (max-width: 420px) {
    .header {
        // width: calc(100% - 75px) !important;
        position: absolute;
        // width: 100vw; // add later and fix errythang and change overflow=x to hidden for main container
        .social-media-icon {
            display: none;
        }
    }
}
