// colours
$dark-blue: #0f2444;
$red: #e41717;
$yellow: #f1c655;
$white: #fff;
$grey: #5c5c5d;
$logo-light-blue: #0fadef;
$logo-red: #da1d3e;
// typography
$myriad-pro: "Myriad Pro";
$myriad-pro-italic: "Myriad Pro Italic";
$acumin-pro: "acumin-pro-condensed";
// more colors idk
$curulean-blue: #2650d4;
$royal-light-blue: #4065dd;
$orange: #ef4923;
$dark-orange: #c13026;
$deep-blue: #14254c;
// misc
$bold: 800;
// exports
:export {
    blue: $dark-blue;
    red: $red;
    logoBlue: $logo-light-blue;
    logoRed: $logo-red;
    white: $white;
}