@import "../../styles/constants";
.auth {
    width: 70% !important;
    min-width: 200px;
    margin: auto;
    margin-bottom: 40px;
    p {
        color: $dark-blue; // change
        font-size: 22px;
    }
    form {
        label {
            color: $dark-blue;
            font-size: 17px;
            &[class*="MuiFormLabel-focused"] {
                color: $dark-blue;
            }
        }
        .buttons {
            margin-top: 20px;
            display: flex;
            &.signup-buttons {
                margin-top: 38px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        .button {
            font-size: 18px;
        }
        *[class*="MuiSelect-select"] {
            text-align: left;
        }
    }
    .message {
        color: $grey;
        font-size: 15px;
        font-weight: 300;
        margin-top: 60px;
        margin-bottom: 20px;
        text-align: left;
        &.info {
            margin: 0;
            text-align: left;
        }
    }
    .login-link {
        small {
            display: flex;
            width: fit-content;
            margin: auto;
            margin-top: 13px;
            .underlined {
                text-decoration: underline;
                margin: 0 3px;
                cursor: pointer;
            }
        }
    }
}

#menu-gender,
#menu-views,
#menu-vote,
#menu-state,
#menu-age {
    z-index: 99999;
}

@media (max-width: 420px) {
    .auth {
        width: 85% !important;
        form .buttons {
            flex-direction: column;
        }
        *[class*="MuiGrid-item"] {
            width: 100%;
        }
    }
}
