@import "../../styles/constants";
.profile-popup {
    padding: 0 45px;

    .heading {
        margin-bottom: 40px;
        color: $dark-blue;
        font-size: 20px;
    }

    .subtitle {
        margin-top: 40px;
        color: $dark-blue;
        border-bottom: 2px solid $dark-blue;
        margin-bottom: 22px;
        display: flex;
        align-items: center;

        h5 {
            margin-right: 5px;
        }
    }

    .reason-expanded {
        color: $dark-blue;
        border-bottom: 2px solid $dark-blue;
        margin-bottom: 23px;
        padding-bottom: 9px;
    }

    .email {
        margin-top: 20px;
        color: grey;
    }

    .edit-button {
        margin: auto;
        display: flex;
        margin-top: 30px;
    }
}
