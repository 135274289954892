@import "../../styles/constants";
.submit-reasons {
    min-width: 200px;
    margin: auto;
    h3 {
        font-size: 22px;
        color: $dark-blue;
    }
    h5,
    h3 {
        width: 75% !important;
        margin: auto;
    }
    .text-field {
        width: 75% !important;
        resize: none;
        white-space: nowrap;
        overflow: hidden;
    }
    *[class*="MuiPrivateTextarea-textarea"] {
        height: 30px;
        overflow: hidden;
    }
    form {
        label {
            color: $dark-blue;
            font-size: 17px;
            &.MuiFormLabel-focused-380 {
                color: $dark-blue;
            }
        }
        .MuiSelect-select-336 {
            text-align: left;
        }
        input {
            &::placeholder {
                font-size: 14px;
            }
        }
    }
    .message {
        color: $dark-blue;
        font-size: 15px;
        font-weight: 300;
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: left;
    }
    &-buttons {
        background-color: $red;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 22px 0 36px;
        margin-top: 45px;
        .button.MuiButton-outlinedPrimary-50 {
            color: $white;
            border-color: $white !important;
            padding: 7px 50px;
            font-size: 20px;
        }
    }
}