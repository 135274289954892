@import "../../../styles/constants";
.image-banner {
    height: 500px;
    position: relative;
    .image-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .main-quote {
            font-size: 60px;
            color: $dark-blue;
            font-weight: $bold;
            width: fit-content;
            margin: auto;
            margin-top: 197px;
            display: flex;
            flex-direction: column;
            span {
                background-color: $white;
                line-height: 58px;
                margin: 5px;
            }
        }
        .secondary-labels {
            display: flex;
            color: $white;
            justify-content: space-between;
            letter-spacing: 0.5px;
            padding: 100px 100px;
            font-size: 22px;
            span {
                min-width: 380px;
                text-align: center;
            }
        }
    }
}
