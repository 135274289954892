.auth {
  width: 70% !important;
  min-width: 200px;
  margin: auto;
  margin-bottom: 40px; }
  .auth p {
    color: #0f2444;
    font-size: 22px; }
  .auth form label {
    color: #0f2444;
    font-size: 17px; }
    .auth form label[class*="MuiFormLabel-focused"] {
      color: #0f2444; }
  .auth form .buttons {
    margin-top: 20px;
    display: flex; }
    .auth form .buttons.signup-buttons {
      margin-top: 38px;
      display: flex;
      flex-direction: column;
      align-items: center; }
  .auth form .button {
    font-size: 18px; }
  .auth form *[class*="MuiSelect-select"] {
    text-align: left; }
  .auth .message {
    color: #5c5c5d;
    font-size: 15px;
    font-weight: 300;
    margin-top: 60px;
    margin-bottom: 20px;
    text-align: left; }
    .auth .message.info {
      margin: 0;
      text-align: left; }
  .auth .login-link small {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-top: 13px; }
    .auth .login-link small .underlined {
      text-decoration: underline;
      margin: 0 3px;
      cursor: pointer; }

#menu-gender,
#menu-views,
#menu-vote,
#menu-state,
#menu-age {
  z-index: 99999; }

@media (max-width: 420px) {
  .auth {
    width: 85% !important; }
    .auth form .buttons {
      flex-direction: column; }
    .auth *[class*="MuiGrid-item"] {
      width: 100%; } }

.profile-popup {
  padding: 0 45px; }
  .profile-popup .heading {
    margin-bottom: 40px;
    color: #0f2444;
    font-size: 20px; }
  .profile-popup .subtitle {
    margin-top: 40px;
    color: #0f2444;
    border-bottom: 2px solid #0f2444;
    margin-bottom: 22px;
    display: flex;
    align-items: center; }
    .profile-popup .subtitle h5 {
      margin-right: 5px; }
  .profile-popup .reason-expanded {
    color: #0f2444;
    border-bottom: 2px solid #0f2444;
    margin-bottom: 23px;
    padding-bottom: 9px; }
  .profile-popup .email {
    margin-top: 20px;
    color: grey; }
  .profile-popup .edit-button {
    margin: auto;
    display: flex;
    margin-top: 30px; }

@font-face {
  font-family: "Myriad Pro";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Condensed"), url(/static/media/MYRIADPRO-COND.8b8a29e2.woff) format("woff"); }

@font-face {
  font-family: "Myriad Pro Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Condensed Italic"), url(/static/media/MYRIADPRO-CONDIT.f6185ef4.woff) format("woff"); }

html {
  margin: 0 auto;
  max-width: 1500px; }

body {
  background-color: #0f2444;
  margin: 0; }

html,
body {
  font-family: "acumin-pro-condensed"; }

span.italics {
  font-style: italic; }

span.underline {
  text-decoration: underline; }

.mobile-only {
  display: none !important; }

h1 {
  font-size: 40px; }

.h2,
h2 {
  font-size: 40px;
  font-weight: 800; }

h3 {
  font-size: 30px;
  font-weight: 800;
  margin: 0; }

h5 {
  font-size: 20px;
  font-weight: 400;
  margin: 0; }

h6 {
  font-size: 20px;
  margin: 0; }

.relative {
  position: relative;
  border-left: 2px solid #0f2444; }

.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.clickable {
  cursor: pointer; }

.centered {
  display: flex;
  flex-direction: column;
  align-items: center; }

.container {
  width: 100%;
  margin: 110px auto 0 auto; }
  .container .MuiGrid-spacing-xs-16-117 {
    width: 100%;
    margin: unset; }
  .container .home-page .quotation {
    display: flex;
    color: #fff;
    font-family: "acumin-pro-condensed";
    font-size: 30px;
    margin: 20px 0; }
  .container .home-page .grid {
    background-color: #fff;
    color: #0f2444;
    text-align: center; }
    .container .home-page .grid .padded-column {
      padding: 6px 10px; }
      @media screen and (max-width: 740px) {
        .container .home-page .grid .padded-column {
          padding: 0; } }
    .container .home-page .grid .blue-bkg {
      background-color: #00aef3; }
      .container .home-page .grid .blue-bkg.first-item {
        padding: 80px 30px 20px 30px; }
      .container .home-page .grid .blue-bkg.second-item {
        padding: 30px 30px 70px 30px; }
    .container .home-page .grid h2 {
      margin-top: 25px;
      margin-bottom: 10px;
      line-height: 42px; }
    .container .home-page .grid h5 {
      font-size: 28px;
      line-height: 28px; }
    .container .home-page .grid .message {
      max-width: 454px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 45px;
      text-align: center;
      letter-spacing: 1px; }
      .container .home-page .grid .message.big {
        max-width: 550px; }
    .container .home-page .grid-column {
      border-right: 2px solid #0f2444; }
    .container .home-page .grid-last {
      border-bottom: 2px solid #0f2444; }
    .container .home-page .grid-item {
      padding: 50px 30px;
      width: 100%;
      max-width: unset;
      min-height: 200px; }
    .container .home-page .grid .small-column {
      max-width: 45%;
      flex-basis: 45%; }
    .container .home-page .grid .big-column {
      max-width: 55%;
      flex-basis: 55%; }
    .container .home-page .grid-overlay {
      position: absolute;
      padding: 74px 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%; }
      .container .home-page .grid-overlay .reason-expanded {
        color: #fff;
        border-bottom: 2px solid #fff;
        padding-bottom: 10px;
        margin-bottom: 50px;
        text-align: left; }
        .container .home-page .grid-overlay .reason-expanded h5 {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 35px; }
      .container .home-page .grid-overlay .action-button {
        margin: auto;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
    .container .home-page .grid-with-bkg {
      padding: unset; }

.popup-overlay {
  z-index: 9999 !important; }
  .popup-overlay .popup-content {
    padding: 0;
    border: 0;
    max-width: 550px;
    z-index: 9999 !important;
    overflow-y: auto;
    max-height: 100vh; }
    .popup-overlay .popup-content .modal.red {
      border: 20px solid #e41717; }
    .popup-overlay .popup-content .modal.blue {
      border: 20px solid #0f2444; }
    .popup-overlay .popup-content .modal .close-container {
      width: 100%;
      display: flex;
      justify-content: flex-end; }
      .popup-overlay .popup-content .modal .close-container a {
        font-size: 46px;
        margin-right: 16px;
        font-weight: 200; }

.MuiMenu-paper-345,
.MuiModal-root-470,
.MuiModal-root-297 {
  z-index: 19999 !important; }

.MuiInput-root-249 {
  width: 100%; }

.MuiGrid-justify-xs-space-between-89,
.MuiGrid-direction-xs-column-73 {
  display: flex; }

.MuiSelect-selectMenu-283 {
  text-align: left; }

.form-control {
  width: 100%; }

.button {
  border-radius: 0 !important;
  text-transform: capitalize !important; }
  .button.MuiButton-outlinedPrimary-50 {
    border: 1px solid #0f2444 !important; }
  .button.MuiButton-root-41 {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 10px 24px; }

.action-buttons {
  font-size: 40px;
  font-weight: 800;
  display: flex;
  text-align: center !important;
  justify-content: space-between;
  background-color: #fff;
  padding: 40px 100px 40px 100px; }

.action-button {
  font-size: 40px;
  font-weight: 800;
  width: 400px;
  color: #fff;
  background-color: #e41717;
  padding: 15px 30px;
  font-weight: bold; }
  .action-button.blue {
    background-color: #0f2444; }
  .action-button.h2 {
    width: unset; }

span.yellow {
  color: #f1c655; }
  span.yellow.padded {
    margin: 0 8px; }

.reasons-grid {
  padding: 0 50px !important;
  color: #fff; }
  .reasons-grid .filters {
    display: flex;
    margin-bottom: 70px; }
    .reasons-grid .filters label {
      color: #fff;
      font-size: 17px; }
      .reasons-grid .filters label.MuiFormLabel-focused-380 {
        color: #fff; }
    .reasons-grid .filters .select {
      border-bottom: 1px solid #fff !important; }
      .reasons-grid .filters .select.big {
        min-width: 96px; }
    .reasons-grid .filters .text-field {
      margin: 0 50px; }
      .reasons-grid .filters .text-field .MuiInput-underline-200:before {
        border-bottom: 1px solid #fff; }
      .reasons-grid .filters .text-field .MuiInput-underline-200:after {
        border-bottom: 1px solid #fff; }
    .reasons-grid .filters #select-region,
    .reasons-grid .filters #select-views,
    .reasons-grid .filters #select-vote,
    .reasons-grid .filters #select-gender,
    .reasons-grid .filters #select-age {
      min-width: 100px; }
    .reasons-grid .filters #select-region,
    .reasons-grid .filters #select-views,
    .reasons-grid .filters #select-vote,
    .reasons-grid .filters #select-gender,
    .reasons-grid .filters #select-age,
    .reasons-grid .filters #age {
      color: #fff; }
    .reasons-grid .filters .MuiInput-underline-200:hover:not(.MuiInput-disabled-199):not(.MuiInput-focused-198):not(.MuiInput-error-201):before {
      border-color: #fff; }
    .reasons-grid .filters svg {
      color: #fff; }
    .reasons-grid .filters .buttons {
      margin-top: 20px;
      display: flex; }
    .reasons-grid .filters .MuiSelect-select-336 {
      text-align: left; }
  .reasons-grid .MuiGrid-grid-sm-12-124 {
    flex-basis: unset; }
  .reasons-grid .progress-container {
    width: 100%;
    justify-content: center;
    display: flex;
    height: 50px;
    margin-bottom: 150px;
    align-items: center; }
    .reasons-grid .progress-container .progress-bar {
      color: #fff; }
  .reasons-grid .content {
    min-height: 200px; }
  .reasons-grid .post-card {
    border: 2px solid #fff;
    border-radius: 0;
    width: calc(100% - 16px);
    margin: auto;
    margin-bottom: 40px;
    background-color: #0f2444;
    position: relative; }
    .reasons-grid .post-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 38px; }
    .reasons-grid .post-card .info {
      text-align: center;
      margin-bottom: 25px; }
    .reasons-grid .post-card .state {
      font-size: 17px; }
    .reasons-grid .post-card .secondary {
      font-size: 16px;
      font-weight: 400; }
    .reasons-grid .post-card .user {
      color: #5c5c5d;
      margin-top: 10px;
      margin-bottom: 30px; }
    .reasons-grid .post-card .reason {
      width: 100%;
      border-bottom: 2px solid #fff;
      padding-bottom: 4px;
      margin-bottom: 30px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical; }
    .reasons-grid .post-card .reason-expanded {
      width: 100%;
      border-bottom: 2px solid #fff;
      padding-bottom: 4px;
      margin-bottom: 30px; }

.home-message {
  display: flex;
  flex-direction: column;
  height: 200px;
  color: #fff;
  background-color: #0f2444;
  text-align: center; }
  .home-message.red {
    background-color: #e41717; }

.footer {
  padding: 60px;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  justify-content: space-between !important; }
  .footer .first-column {
    align-items: flex-start !important; }
  .footer .copyright {
    color: #0f2444;
    margin-top: 10px; }
  .footer .social-media-icon {
    min-width: unset;
    margin-top: 20px; }
  .footer .link {
    display: block;
    font-family: "acumin-pro-condensed";
    font-size: 20px;
    color: #0f2444;
    margin-bottom: 2px; }
  .footer .email-icon {
    margin-top: 10px; }
  .footer .MuiGrid-justify-xs-center-87 {
    justify-content: center;
    align-items: center; }

.about-page-banner {
  width: 100%;
  height: 220px;
  object-fit: cover; }

.about-page .page-title {
  color: #fff;
  text-align: center;
  margin-bottom: 38px; }
  .about-page .page-title h1 {
    font-size: 36px; }
  .about-page .page-title.contact {
    margin-bottom: 25px;
    margin-top: 50px; }

.about-page-content {
  display: flex;
  max-width: 805px;
  margin: auto;
  width: 70%;
  color: #fff; }
  .about-page-content h5 {
    flex: 1 1;
    padding: 0 40px;
    font-size: 22px; }

.about-page form {
  width: 300px;
  margin: auto;
  margin-bottom: 200px;
  align-items: center;
  display: flex;
  flex-direction: column; }

.about-page .MuiFormLabel-root-85 {
  color: #fff; }

.about-page .text-field .MuiInput-underline-96:before {
  border-bottom: 1px solid #fff; }

.about-page .text-field .MuiInput-underline-96:after {
  border-bottom: 1px solid #fff; }

.about-page .MuiOutlinedInput-multiline-128 {
  padding: 0; }

.about-page .MuiInputBase-inputType-118 {
  font-size: 18px; }

.about-page .MuiOutlinedInput-root-122 .MuiOutlinedInput-notchedOutline-129 {
  border: none; }

.about-page #email,
.about-page #message {
  color: #fff; }

.about-page #message {
  border: 1px solid #fff;
  margin-top: 24px;
  height: auto;
  padding: 10px 14px; }

.about-page .MuiInput-underline-96:hover:not(.MuiInput-disabled-95):not(.MuiInput-focused-94):not(.MuiInput-error-97):before {
  border-color: #fff; }

.about-page .button {
  color: #fff;
  border-color: #fff !important;
  font-size: 18px;
  margin-top: 20px; }

.contribute-page-banner {
  width: 100%;
  height: 220px;
  object-fit: cover; }

.contribute-page .page-title {
  color: #fff;
  text-align: center;
  margin-bottom: 38px; }
  .contribute-page .page-title h1 {
    font-size: 36px; }
  .contribute-page .page-title.contact {
    margin-bottom: 25px;
    margin-top: 50px; }

.contribute-page-content {
  display: flex;
  max-width: 805px;
  margin: auto;
  width: 70%;
  color: #fff; }
  .contribute-page-content h5 {
    flex: 1 1;
    padding: 0 40px;
    font-size: 22px; }

.contribute-page form {
  width: 300px;
  margin: auto;
  margin-bottom: 200px;
  align-items: center;
  display: flex;
  flex-direction: column; }

.contribute-page .MuiFormLabel-root-85 {
  color: #fff; }

.contribute-page .text-field .MuiInput-underline-96:before {
  border-bottom: 1px solid #fff; }

.contribute-page .text-field .MuiInput-underline-96:after {
  border-bottom: 1px solid #fff; }

.contribute-page .MuiOutlinedInput-multiline-128 {
  padding: 0; }

.contribute-page .MuiInputBase-inputType-118 {
  font-size: 18px; }

.contribute-page .MuiOutlinedInput-root-122 .MuiOutlinedInput-notchedOutline-129 {
  border: none; }

.contribute-page #email,
.contribute-page #message {
  color: #fff; }

.contribute-page #message {
  border: 1px solid #fff;
  margin-top: 24px;
  height: auto;
  padding: 10px 14px; }

.contribute-page .MuiInput-underline-96:hover:not(.MuiInput-disabled-95):not(.MuiInput-focused-94):not(.MuiInput-error-97):before {
  border-color: #fff; }

.contribute-page .button {
  color: #fff;
  background-color: #2650d4;
  border-color: #2650d4 !important;
  font-size: 18px;
  margin-top: 20px;
  width: 100px; }
  .contribute-page .button.left {
    margin-right: 10px;
    margin-left: 10px; }

.contribute-page .button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; }
  .contribute-page .button-container .text-field {
    margin: 0 auto; }

@media screen and (min-width: 900px) and (max-width: 980px) {
  .large-desktop-only {
    display: none !important; }
  .action-buttons {
    font-size: 20px;
    font-weight: 400;
    padding: 0;
    padding: 40px 40px;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important; }
  .action-button {
    font-size: 40px;
    font-weight: 800;
    overflow: hidden; } }

@media screen and (min-width: 825px) and (max-width: 900px) {
  .large-desktop-only {
    display: none !important; }
  .medium-desktop-only {
    display: none !important; }
  .action-buttons {
    padding: 0;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important; }
  .action-button {
    font-size: 25px !important;
    font-weight: 800 !important;
    min-width: 300px !important;
    height: auto !important; } }

@media screen and (min-width: 740px) and (max-width: 825px) {
  html {
    align-items: center;
    justify-content: center;
    overflow-x: hidden !important; }
  .large-desktop-only {
    display: none !important; }
  .medium-desktop-only {
    display: none !important; }
  .action-buttons {
    padding: 0;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-direction: column;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px; }
  .action-button {
    font-size: 25px !important;
    font-weight: 800 !important;
    width: 90%;
    min-width: 300px !important;
    height: auto !important;
    padding: 10px 10px !important;
    margin-bottom: 20px !important; }
    .action-button.blue {
      margin-bottom: 0px !important; } }

@media screen and (min-width: 420px) and (max-width: 740px) {
  html {
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  .large-desktop-only {
    display: none !important; }
  .medium-desktop-only {
    display: none !important; }
  .action-buttons {
    padding: 0;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-direction: column;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px; }
  .action-button {
    font-size: 25px !important;
    font-weight: 800 !important;
    width: 90%;
    min-width: 300px !important;
    height: auto !important;
    padding: 10px 10px !important;
    margin-bottom: 20px !important; }
    .action-button.blue {
      margin-bottom: 0px !important; }
  h2,
  .h2 {
    font-size: 32px; }
  .grid[class*="MuiGrid-container"] {
    width: unset;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important; }
  .grid-with-bkg {
    min-height: 500px !important;
    padding: 0 !important; }
  .grid-overlay {
    padding: 42px 25px !important; }
  .grid .reason-expanded {
    margin-bottom: 40px !important; }
    .grid .reason-expanded h5 {
      font-size: 24px !important;
      line-height: 25px !important; }
  [class*="MuiGrid-spacing-xs"] {
    width: 100%;
    margin: 0; }
  .popup-overlay .popup-content {
    width: calc(100% - 30px); }
  .container .home-page {
    overflow-wrap: normal; }
    .container .home-page .grid-item {
      width: 100%;
      text-align: center !important;
      justify-content: center !important;
      padding: 20px 20px; }
      .container .home-page .grid-item *.action-button {
        margin-top: 30px;
        margin-bottom: 30px; }
      .container .home-page .grid-item .h5,
      .container .home-page .grid-item .message {
        margin: 0 auto;
        text-align: center;
        overflow: wrap;
        font-size: 30px;
        letter-spacing: 0.7px;
        margin-bottom: 15px; }
    .container .home-page .grid-column {
      border-bottom: 2px solid #0f2444;
      border-right: unset;
      text-align: center !important;
      justify-content: center !important;
      align-items: center !important; }
      .container .home-page .grid-column .h5,
      .container .home-page .grid-column .message {
        margin: 0 auto;
        text-align: center;
        font-size: 30px;
        overflow: wrap;
        width: calc(100% - 200px); }
        .container .home-page .grid-column .h5.big,
        .container .home-page .grid-column .message.big {
          max-width: calc(100% - 200px); }
    .container .home-page *[class*="MuiGrid-grid-xs"][class*="MuiGrid-grid-sm"] {
      max-width: 100%;
      width: 100vw !important; }
  .reasons-grid {
    padding: 0 30px !important;
    margin: 0; }
    .reasons-grid .MuiGrid-item-71 {
      margin: 0;
      box-sizing: border-box;
      width: 100%; }
    .reasons-grid .post-card {
      width: 100%; }
    .reasons-grid .post-card-content {
      padding: 30px 18px; }
    .reasons-grid .filters {
      flex-direction: column;
      width: 100%; }
      .reasons-grid .filters .text-field {
        margin: 0; }
      .reasons-grid .filters .form-control {
        margin: 0 !important; }
  .MuiGrid-spacing-xs-16-93 {
    width: 100% !important;
    margin: 0 !important; }
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px; }
    .button-container .text-field {
      margin: 0 auto; }
  .help {
    flex-direction: column; }
  .lol {
    max-width: 100vw !important; } }

@media (max-width: 420px) {
  html {
    align-items: center;
    justify-content: center;
    overflow-x: hidden !important; }
  .large-desktop-only {
    display: none !important; }
  .desktop-only {
    display: none !important; }
  .medium-desktop-only {
    display: none !important; }
  .mobile-only {
    display: flex !important; }
  .header .nav-container {
    width: 100% !important; }
  .help {
    flex-direction: unset; }
  .lol {
    max-width: unset; }
  h2,
  .h2 {
    font-size: 32px; }
  .footer {
    display: flex;
    position: relative;
    align-items: center;
    text-align: center;
    justify-content: center; }
    .footer *[class*="MuiGrid-justify-xs-center"] {
      text-align: center; }
    .footer *[class*="MuiGrid-align-items-xs-flex-start"] {
      align-items: center;
      margin-bottom: 40px;
      margin-top: 35px; }
    .footer .quotation {
      margin-top: 35px; }
  .about-page-banner {
    height: 100px; }
  .about-page-content {
    width: 100%;
    color: #fff;
    flex-direction: column;
    margin-bottom: 50px; }
    .about-page-content h5 {
      margin-bottom: 28px; }
  .container .home-page .quotation {
    width: 100vw !important;
    font-size: 2vh;
    text-align: center;
    align-items: center !important;
    justify-content: center !important;
    padding: 20px 20px;
    margin: 0 auto; }
  .grid *.footer {
    margin: "none"; }
  .image-banner .image-overlay .main-quote {
    width: 100vw !important;
    font-size: 5vh !important; }
  .action-buttons {
    flex-direction: column;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px; }
  .action-button {
    padding: 10px 10px !important;
    margin-bottom: 20px !important; }
    .action-button.blue {
      margin-bottom: 0px !important; }
  .image-banner .image-overlay .secondary-labels {
    padding: 100px 70px;
    text-align: center;
    font-size: 22px;
    justify-content: center; }
    .image-banner .image-overlay .secondary-labels .large-desktop-only {
      font-weight: 600; }
  .home-message {
    flex-direction: column;
    height: auto;
    padding: 25px; }
    .home-message.h2 {
      font-size: 40px; }
      .home-message.h2.mobile-only {
        font-size: 25px; }
  .grid {
    width: unset; }
    .grid[class*="MuiGrid-container"] {
      width: unset;
      flex-direction: column;
      align-items: center !important;
      justify-content: center !important; }
    .grid-with-bkg {
      min-height: 500px !important;
      padding: 0 !important; }
    .grid-overlay {
      padding: 42px 25px !important; }
    .grid .reason-expanded {
      margin-bottom: 40px !important; }
      .grid .reason-expanded h5 {
        font-size: 24px !important;
        line-height: 25px !important; }
  [class*="MuiGrid-spacing-xs"] {
    width: 100%;
    margin: 0; }
  .popup-overlay .popup-content {
    width: calc(100% - 30px); }
  .container .home-page {
    overflow-wrap: normal; }
    .container .home-page .grid-item {
      text-align: center !important;
      justify-content: center !important;
      padding: 20px 20px; }
      .container .home-page .grid-item *.action-button {
        margin-top: 20px; }
      .container .home-page .grid-item .h5,
      .container .home-page .grid-item .message {
        margin: 0 auto;
        text-align: center;
        overflow: wrap;
        font-size: 20px;
        letter-spacing: 0.7px;
        margin-bottom: 15px; }
    .container .home-page .grid-column {
      border-bottom: 2px solid #0f2444;
      border-right: unset;
      text-align: center !important;
      justify-content: center !important;
      align-items: center !important; }
      .container .home-page .grid-column .h5,
      .container .home-page .grid-column .message {
        margin: 0 auto;
        text-align: center;
        font-size: 20px;
        overflow: wrap; }
  .reasons-grid {
    padding: 0 30px !important;
    margin: 0; }
    .reasons-grid .MuiGrid-item-71 {
      margin: 0;
      box-sizing: border-box;
      width: 100%; }
    .reasons-grid .post-card {
      width: 100%; }
    .reasons-grid .post-card-content {
      padding: 30px 18px; }
    .reasons-grid .filters {
      flex-direction: column;
      width: 100%; }
      .reasons-grid .filters .text-field {
        margin: 0; }
      .reasons-grid .filters .form-control {
        margin: 0 !important; }
  .MuiGrid-spacing-xs-16-93 {
    width: 100% !important;
    margin: 0 !important; }
  .container .home-page .grid {
    display: unset; }
  .container .home-page .grid-overlay {
    width: unset; }
  .footer {
    all: unset;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px; }
  .help {
    width: unset; }
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px; }
    .button-container .text-field {
      margin: 0 auto; } }

.submit-reasons {
  min-width: 200px;
  margin: auto; }
  .submit-reasons h3 {
    font-size: 22px;
    color: #0f2444; }
  .submit-reasons h5,
  .submit-reasons h3 {
    width: 75% !important;
    margin: auto; }
  .submit-reasons .text-field {
    width: 75% !important;
    resize: none;
    white-space: nowrap;
    overflow: hidden; }
  .submit-reasons *[class*="MuiPrivateTextarea-textarea"] {
    height: 30px;
    overflow: hidden; }
  .submit-reasons form label {
    color: #0f2444;
    font-size: 17px; }
    .submit-reasons form label.MuiFormLabel-focused-380 {
      color: #0f2444; }
  .submit-reasons form .MuiSelect-select-336 {
    text-align: left; }
  .submit-reasons form input::-webkit-input-placeholder {
    font-size: 14px; }
  .submit-reasons form input::-ms-input-placeholder {
    font-size: 14px; }
  .submit-reasons form input::placeholder {
    font-size: 14px; }
  .submit-reasons .message {
    color: #0f2444;
    font-size: 15px;
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left; }
  .submit-reasons-buttons {
    background-color: #e41717;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 22px 0 36px;
    margin-top: 45px; }
    .submit-reasons-buttons .button.MuiButton-outlinedPrimary-50 {
      color: #fff;
      border-color: #fff !important;
      padding: 7px 50px;
      font-size: 20px; }



.header {
  background-color: #0f2444;
  border-bottom: 2px solid white;
  min-height: 110px; }
  .header .nav-container {
    max-width: 1500px;
    justify-content: space-between;
    width: calc(100% - 48px); }
    .header .nav-container .social-media-icon {
      min-width: 48px; }
    .header .nav-container .right-content {
      display: flex;
      align-items: center; }
  .header .logo-icon {
    transform: scale(0.7);
    margin-left: -14px; }
  .header .contribute-button {
    color: #fff;
    font-weight: bold;
    font-size: 23px;
    padding: 0 14px 5px;
    margin-left: 22px;
    margin-right: 10px; }
  .header .sign-in-button {
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    letter-spacing: 0.4px; }
  .header .dropdown-container .dropdown-menu {
    position: absolute;
    background-color: white;
    padding: 12px;
    right: 0px;
    border-radius: 0px;
    display: flex;
    flex-direction: column; }
    .header .dropdown-container .dropdown-menu-item {
      padding: 5px 0;
      cursor: pointer;
      color: #0f2444; }

@media (max-width: 420px) {
  .header {
    position: absolute; }
    .header .social-media-icon {
      display: none; } }

.background-image {
  object-fit: cover; }

.bkg-opacity {
  top: 0;
  left: 0; }

.layer {
  position: absolute;
  width: 100%;
  height: 100%; }

.image-banner {
  height: 500px;
  position: relative; }
  .image-banner .image-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; }
    .image-banner .image-overlay .main-quote {
      font-size: 60px;
      color: #0f2444;
      font-weight: 800;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: auto;
      margin-top: 197px;
      display: flex;
      flex-direction: column; }
      .image-banner .image-overlay .main-quote span {
        background-color: #fff;
        line-height: 58px;
        margin: 5px; }
    .image-banner .image-overlay .secondary-labels {
      display: flex;
      color: #fff;
      justify-content: space-between;
      letter-spacing: 0.5px;
      padding: 100px 100px;
      font-size: 22px; }
      .image-banner .image-overlay .secondary-labels span {
        min-width: 380px;
        text-align: center; }

    * {
        box-sizing: border-box;
    }
    
    input,
    button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        border-style: none;
    }
    /* html {
    background-color: #6772e5;
    font-size: 16px;
    font-family: Roboto, Open Sans, Segoe UI, sans-serif;
    font-weight: 500;
    font-style: normal;
    text-rendering: optimizeLegibility;
    height: 100%;
} */
    
    body {
        height: 100%;
        margin: 0;
    }
    
    #root {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .AppWrapper {
        height: 100%;
        display: block;
        align-items: center;
        justify-content: center;
        width: 500px;
        /* height: 400px; */
        position: relative;
    }
    
    @-webkit-keyframes fade {
        from {
            opacity: 0;
            transform: scale3D(0.95, 0.95, 0.95);
        }
        to {
            opacity: 1;
            transform: scale3D(1, 1, 1);
        }
    }
    
    @keyframes fade {
        from {
            opacity: 0;
            transform: scale3D(0.95, 0.95, 0.95);
        }
        to {
            opacity: 1;
            transform: scale3D(1, 1, 1);
        }
    }
    
    .Form {
        -webkit-animation: fade 200ms ease-out;
                animation: fade 200ms ease-out;
    }
    
    .FormGroup {
        margin: 0 15px 20px;
        padding: 0;
        border-style: none;
        background-color: #7795f8;
        will-change: opacity, transform;
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
        border-radius: 4px;
    }
    
    .FormRow {
        display: flex;
        align-items: center;
        margin-left: 15px;
        border-top: 1px solid #819efc;
    }
    
    .FormRow:first-child {
        border-top: none;
    }
    
    .FormRowLabel {
        width: 15%;
        min-width: 70px;
        padding: 11px 0;
        color: #c4f0ff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    @-webkit-keyframes void-animation-out {
        0%,
        to {
            opacity: 1;
        }
    }
    
    @keyframes void-animation-out {
        0%,
        to {
            opacity: 1;
        }
    }
    
    .FormRowInput:-webkit-autofill {
        -webkit-text-fill-color: #fce883;
        /* Hack to hide the default webkit autofill */
        -webkit-transition: background-color 100000000s;
        transition: background-color 100000000s;
        -webkit-animation: 1ms void-animation-out;
                animation: 1ms void-animation-out;
    }
    
    .FormRowInput {
        font-size: 16px;
        width: 100%;
        padding: 11px 15px 11px 0;
        color: #fff;
        background-color: transparent;
        -webkit-animation: 1ms void-animation-out;
                animation: 1ms void-animation-out;
    }
    
    .FormRowInput::-webkit-input-placeholder {
        color: #87bbfd;
    }
    
    .FormRowInput::-ms-input-placeholder {
        color: #87bbfd;
    }
    
    .FormRowInput::placeholder {
        color: #87bbfd;
    }
    
    .StripeElement--webkit-autofill {
        background: transparent !important;
    }
    
    .StripeElement {
        width: 100%;
        padding: 11px 15px 11px 0;
    }
    
    .SubmitButton {
        display: block;
        font-size: 16px;
        width: calc(100% - 30px);
        height: 40px;
        margin: 40px 15px 0;
        background-color: #f6a4eb;
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;
        border-radius: 4px;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        transition: all 100ms ease-in-out;
        will-change: transform, background-color, box-shadow;
    }
    
    .SubmitButton:active {
        background-color: #d782d9;
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
        transform: scale(0.99);
    }
    
    .SubmitButton.SubmitButton--error {
        transform: translateY(15px);
    }
    
    .SubmitButton.SubmitButton--error:active {
        transform: scale(0.99) translateY(15px);
    }
    
    .SubmitButton:disabled {
        opacity: 0.5;
        cursor: default;
        background-color: #7795f8;
        box-shadow: none;
    }
    
    .ErrorMessage {
        color: #fff;
        position: absolute;
        display: flex;
        justify-content: center;
        padding: 0 15px;
        font-size: 13px;
        margin-top: 0px;
        width: 100%;
        transform: translateY(-15px);
        opacity: 0;
        -webkit-animation: fade 150ms ease-out;
                animation: fade 150ms ease-out;
        -webkit-animation-delay: 50ms;
                animation-delay: 50ms;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        will-change: opacity, transform;
    }
    
    .ErrorMessage svg {
        margin-right: 10px;
    }
    
    .Result {
        margin-top: 50px;
        text-align: center;
        -webkit-animation: fade 200ms ease-out;
                animation: fade 200ms ease-out;
    }
    
    .ResultTitle {
        color: #fff;
        font-weight: 500;
        margin-bottom: 8px;
        font-size: 17px;
        text-align: center;
    }
    
    .ResultMessage {
        color: #9cdbff;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 25px;
        line-height: 1.6em;
        text-align: center;
    }
    
    .ResetButton {
        border: 0;
        cursor: pointer;
        background: transparent;
    }
    
    @media screen and (max-width: 500px) {
        .beepboop {
            width: 100vw !important;
        }
    }
.nav-container {
    margin: auto;
}
.nav-container svg {
    color: #fff;
}
a {
    text-decoration: none;
}

